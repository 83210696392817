import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"   viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m3470 2031 c77 -21 159 -72 216 -135 30 -32 54 -67 54 -75 0 -9 -15
-24 -32 -34 -36 -21 -29 -25 -87 49 -31 40 -106 85 -174 105 -67 21 -122 22
-213 3 -30 -6 -33 -4 -44 31 -14 41 -5 54 35 55 11 0 27 5 35 10 19 13 150 7
210 -9z m26 -157 c56 -28 134 -102 134 -127 0 -8 -15 -22 -34 -32 l-33 -18
-37 40 c-20 23 -58 51 -84 63 -40 19 -58 22 -123 17 l-75 -5 -12 30 c-22 58 0
68 131 63 61 -2 90 -9 133 -31z m-85 -110 c37 -8 99 -62 99 -84 0 -11 -11 -27
-24 -35 l-25 -16 25 -36 c35 -53 33 -116 -9 -195 -18 -35 -41 -83 -51 -108
-11 -25 -57 -130 -103 -233 -45 -103 -83 -194 -83 -203 0 -16 -60 -161 -104
-251 -14 -29 -26 -55 -26 -58 0 -4 -10 -27 -21 -53 -66 -144 -78 -175 -73
-183 3 -6 39 -26 80 -45 41 -19 74 -40 74 -47 0 -39 -90 -229 -152 -320 -50
-74 -177 -203 -248 -253 -77 -55 -122 -77 -225 -114 -80 -29 -105 -33 -225
-37 -84 -3 -158 -1 -195 7 -33 6 -67 13 -75 14 -45 7 -181 74 -235 116 -27 21
-62 42 -78 45 -22 6 -82 63 -74 72 2 1 20 -4 40 -11 29 -10 137 -12 445 -12
446 1 445 1 506 62 35 35 57 81 286 604 134 305 405 920 461 1043 53 119 54
172 3 222 -34 34 -41 36 -94 26 -3 -1 2 -6 10 -11 10 -6 -112 -9 -350 -7
l-365 2 339 2 338 3 -6 44 c-6 37 -4 46 11 55 17 11 69 9 124 -5z m-956 -55
c151 -37 166 -45 110 -53 -95 -15 -160 -69 -202 -166 -13 -30 -28 -64 -33 -75
-5 -11 -37 -85 -71 -165 -89 -210 -103 -236 -137 -248 -39 -13 -128 -106 -157
-162 -43 -86 -58 -161 -52 -268 l5 -97 -80 -185 c-44 -102 -104 -241 -134
-309 -35 -79 -54 -138 -54 -162 0 -46 -8 -48 -42 -11 -73 82 -162 245 -207
377 -105 316 -87 674 50 964 127 270 332 457 593 543 109 36 300 44 411 17z
m926 -95 c11 -14 10 -18 -6 -30 -20 -15 -45 -19 -45 -6 0 4 -3 17 -6 30 -5 18
-2 22 19 22 13 0 31 -7 38 -16z m-781 -73 c0 -6 -11 -22 -25 -37 -14 -15 -25
-30 -25 -34 0 -4 -11 -20 -24 -35 -13 -15 -22 -34 -19 -42 6 -17 -13 -17 -21
0 -3 6 -5 -11 -3 -39 2 -47 0 -51 -18 -49 -18 1 -20 -3 -16 -38 2 -27 0 -38
-8 -33 -7 5 -11 -3 -11 -23 0 -16 4 -32 8 -35 5 -3 7 -41 4 -85 -6 -105 4
-188 24 -188 7 0 14 5 14 10 0 6 5 5 13 -1 6 -5 26 -13 43 -16 52 -11 125
-101 101 -125 -8 -8 -9 -16 -1 -29 12 -18 11 -133 -1 -169 -7 -21 -4 -23 27
-23 23 0 45 9 68 29 34 28 60 31 60 7 0 -6 22 -28 49 -49 27 -20 51 -44 54
-54 2 -10 3 -6 1 9 -5 38 12 44 20 8 6 -31 -15 -88 -85 -237 -16 -34 -29 -66
-29 -72 0 -6 -5 -11 -10 -11 -17 0 -220 159 -292 229 -58 56 -66 67 -54 81 11
13 7 21 -30 55 -24 22 -50 41 -58 41 -8 1 -59 -34 -113 -78 -54 -44 -120 -97
-148 -119 -27 -22 -68 -55 -90 -74 -43 -38 -58 -44 -49 -20 7 18 -7 20 -24 3
-9 -9 -12 -9 -13 2 0 10 -4 7 -9 -5 -7 -17 -9 -15 -9 12 -1 17 3 34 8 38 5 3
17 29 27 58 9 28 27 75 39 102 12 28 39 88 60 135 60 138 102 220 109 213 3
-4 6 1 6 9 0 13 7 14 35 8 19 -4 35 -6 35 -3 0 3 6 0 14 -6 19 -16 31 7 41 74
22 149 130 384 229 500 35 40 38 45 66 83 25 34 30 38 30 23z m65 -218 c0 -20
5 -33 12 -33 7 0 13 11 13 25 0 27 25 45 63 45 35 0 50 -22 32 -46 -8 -10 -15
-28 -15 -39 -1 -40 -91 -61 -110 -25 -10 19 -11 19 -30 0 -23 -23 -72 -26 -90
-5 -34 41 31 119 95 113 27 -3 30 -7 30 -35z m245 28 c-32 -9 -41 -51 -12 -51
26 0 35 -25 15 -42 -14 -11 -15 -10 -9 10 5 16 3 22 -8 23 -35 3 -39 4 -43 14
-6 18 36 55 60 54 20 -1 20 -1 -3 -8z m148 -3 c7 -7 12 -19 12 -29 0 -20 -37
-69 -53 -69 -9 0 -9 2 -1 8 15 10 34 47 34 67 0 24 -29 29 -52 9 -33 -30 -41
-64 -22 -85 20 -22 6 -25 -20 -6 -24 17 -15 47 25 87 32 32 57 38 77 18z
m-415 -162 c15 -2 46 -3 69 -4 39 -2 41 -3 26 -19 -14 -13 -20 -14 -32 -4 -11
9 -20 10 -34 2 -31 -17 -173 -19 -179 -4 -12 32 5 41 64 36 32 -3 70 -6 86 -7z
m137 -16 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m-600 -260 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z m-1522 -2081 c9 -16 26 -46 38 -65 l20 -36 33 62 c34 63 54 81 73 62 7
-7 -5 -35 -36 -89 -34 -60 -46 -90 -46 -121 0 -38 -2 -42 -25 -42 -22 0 -25 4
-25 39 0 28 -13 61 -45 117 -25 44 -45 85 -45 92 0 25 41 12 58 -19z m401 6
c12 -12 16 -37 16 -111 0 -117 -6 -124 -109 -124 -39 0 -76 4 -82 8 -23 15
-34 53 -34 119 0 78 15 114 50 125 40 12 141 2 159 -17z m89 -83 l3 -102 59 0
60 0 0 89 c0 103 5 121 31 121 17 0 19 -9 19 -110 0 -140 -7 -150 -108 -150
-101 0 -106 7 -110 130 -4 112 0 133 25 128 16 -3 18 -17 21 -106z m388 83
c21 -29 18 -91 -6 -115 l-21 -21 26 -43 c31 -53 31 -56 1 -56 -19 0 -31 11
-53 50 -25 44 -32 50 -60 50 -33 0 -33 0 -33 -50 0 -47 -2 -50 -25 -50 -24 0
-25 2 -25 75 l0 75 79 0 c61 0 80 3 85 15 13 36 -3 45 -85 45 -70 0 -79 2 -79
18 0 31 12 34 99 30 67 -4 86 -8 97 -23z m343 19 c47 -10 64 -49 59 -140 -5
-100 -18 -114 -113 -114 -101 0 -109 8 -114 114 -4 120 10 141 105 145 17 0
46 -2 63 -5z m149 -12 c11 -11 39 -54 63 -98 l44 -79 3 93 c2 73 6 94 18 99
27 10 34 -18 33 -122 -1 -115 -9 -135 -47 -135 -25 0 -34 11 -83 96 l-54 96
-3 -96 c-2 -88 -4 -96 -22 -96 -19 0 -20 7 -20 124 0 125 2 133 38 135 6 1 20
-7 30 -17z m370 -9 c3 -23 1 -23 -97 -23 -91 0 -101 2 -101 18 0 30 10 33 105
30 85 -3 90 -4 93 -25z m331 16 c37 -13 46 -38 46 -131 0 -58 2 -68 17 -68 13
0 18 -8 18 -25 l0 -25 -110 0 c-145 0 -150 4 -150 128 0 72 3 92 18 104 31 28
107 36 161 17z m151 -94 l5 -100 60 0 60 0 5 100 c5 92 7 100 25 100 19 0 20
-7 20 -106 0 -139 -7 -149 -110 -149 -99 0 -109 11 -113 136 -4 106 0 127 25
122 15 -3 19 -18 23 -103z m240 -25 c0 -123 -1 -130 -20 -130 -19 0 -20 7 -20
130 0 123 1 130 20 130 19 0 20 -7 20 -130z m218 103 c3 -22 0 -23 -67 -23
-81 0 -85 -4 -79 -101 l3 -54 70 -5 c63 -5 70 -7 73 -27 3 -22 1 -23 -77 -23
-74 0 -81 2 -100 26 -17 21 -21 41 -21 95 0 120 19 140 130 136 59 -2 65 -4
68 -24z m70 -31 c4 -70 30 -72 69 -4 29 50 40 62 61 62 19 0 14 -25 -16 -77
l-29 -48 33 -60 c19 -33 34 -63 34 -67 0 -5 -11 -8 -25 -8 -20 0 -30 10 -50
50 -20 40 -30 50 -50 50 -23 0 -25 -3 -25 -50 0 -47 -2 -50 -25 -50 l-25 0 0
123 c0 68 3 127 7 131 4 4 14 5 23 4 11 -2 16 -18 18 -56z m490 31 c3 -22 0
-23 -67 -23 l-71 0 0 -30 0 -30 65 0 c73 0 85 -11 85 -75 0 -69 -9 -75 -111
-75 -88 0 -90 1 -87 23 3 20 9 22 76 25 68 3 72 4 72 26 0 22 -5 24 -61 28
-71 5 -89 21 -89 78 0 65 23 80 120 77 59 -2 65 -4 68 -24z m80 -81 l3 -102
59 0 59 0 3 103 c3 94 4 102 23 102 19 0 20 -8 23 -107 3 -138 -4 -148 -108
-148 -102 0 -110 10 -110 143 0 107 3 120 30 115 12 -3 16 -23 18 -106z m394
90 c11 -11 14 -48 8 -97 -1 -11 1 -37 5 -57 6 -28 3 -44 -9 -63 -16 -25 -20
-26 -109 -23 l-92 3 -3 115 c-3 142 -2 144 111 137 43 -2 83 -9 89 -15z
m-1974 -114 c-3 -21 -9 -23 -76 -26 -63 -2 -73 -5 -70 -20 3 -13 18 -18 73
-22 67 -5 70 -6 73 -32 l3 -28 -79 0 c-90 0 -106 10 -117 72 -14 72 -6 78 101
78 93 0 95 -1 92 -22z m-1663 -550 l18 -63 19 63 c23 77 44 80 67 10 9 -26 19
-49 22 -52 3 -3 13 19 23 49 10 33 23 55 32 55 19 0 18 -12 -10 -95 -17 -54
-27 -71 -43 -73 -17 -2 -24 5 -33 35 -6 21 -14 45 -17 53 -3 9 -12 -5 -22 -35
-12 -38 -21 -50 -36 -50 -16 0 -25 14 -42 65 -26 76 -28 100 -9 100 8 0 21
-25 31 -62z m306 2 c17 -57 18 -57 42 15 22 63 43 57 65 -17 l19 -63 17 58
c15 51 37 79 52 65 2 -3 -8 -41 -23 -84 -22 -63 -32 -79 -48 -79 -15 0 -24 12
-36 50 l-16 50 -17 -53 c-15 -44 -22 -53 -39 -50 -16 2 -25 19 -44 78 -27 90
-27 92 -6 88 11 -2 23 -23 34 -58z m304 -2 l18 -63 20 63 c23 76 43 79 66 10
9 -26 19 -49 22 -52 3 -3 13 19 23 49 10 33 23 55 32 55 7 0 14 -5 14 -12 0
-27 -46 -151 -57 -155 -20 -8 -41 15 -49 50 -11 53 -17 53 -34 1 -25 -73 -49
-68 -77 16 -26 76 -28 100 -9 100 8 0 21 -25 31 -62z m408 12 c11 -27 24 -50
27 -50 4 0 13 18 20 40 15 46 33 65 55 56 22 -8 22 -166 0 -166 -12 0 -15 13
-16 58 l-1 57 -20 -47 c-27 -63 -52 -64 -78 -5 l-19 42 0 -52 c-1 -46 -4 -53
-21 -53 -18 0 -20 7 -20 69 0 83 6 101 32 101 14 0 26 -14 41 -50z m227 10
c11 -22 22 -40 25 -40 3 0 14 18 25 40 11 22 27 40 35 40 21 0 19 -6 -15 -67
-16 -29 -30 -64 -30 -78 0 -16 -6 -25 -15 -25 -9 0 -15 9 -15 23 0 12 -14 46
-30 76 -34 61 -36 71 -15 71 8 0 24 -18 35 -40z m297 14 c7 -10 13 -39 13 -66
0 -26 5 -48 10 -48 6 0 10 -7 10 -15 0 -12 -15 -15 -73 -15 -43 0 -78 5 -85
12 -13 13 -16 116 -4 134 19 29 107 27 129 -2z m123 -45 l5 -64 35 0 35 0 5
64 c3 39 10 66 18 69 16 5 17 -139 0 -156 -16 -16 -100 -16 -116 0 -17 17 -16
161 1 156 7 -3 14 -30 17 -69z m198 -17 c2 -68 0 -82 -13 -82 -12 0 -15 15
-15 86 0 64 3 85 13 82 8 -3 13 -32 15 -86z m187 68 c0 -11 -13 -16 -47 -18
l-48 -3 0 -49 0 -49 48 -3 c64 -4 55 -23 -10 -23 -48 0 -54 3 -66 28 -20 42
-8 115 21 127 35 14 102 8 102 -10z m86 8 c1 -7 1 -23 1 -35 -2 -37 32 -28 51
12 9 19 23 35 32 35 20 0 19 -12 -5 -52 -19 -34 -19 -34 0 -66 24 -38 25 -52
6 -52 -8 0 -24 16 -35 36 -12 19 -27 34 -34 31 -11 -4 -13 -9 -16 -49 0 -10
-8 -18 -16 -18 -12 0 -15 15 -15 78 0 43 3 82 7 85 10 11 23 8 24 -5z m284 -8
c0 -11 -13 -16 -47 -18 -34 -2 -48 -7 -48 -18 0 -10 12 -14 38 -14 43 0 62
-14 62 -46 0 -47 -9 -54 -71 -54 -46 0 -59 3 -59 15 0 11 12 15 51 15 43 0 50
3 47 18 -2 12 -16 18 -48 22 -43 5 -45 7 -48 40 -5 45 18 62 78 58 32 -2 45
-7 45 -18z m93 -53 l3 -67 39 0 39 0 3 67 c2 42 8 68 16 71 16 5 17 -139 0
-156 -16 -16 -100 -16 -116 0 -17 17 -16 161 1 156 7 -3 13 -29 15 -71z m292
63 c9 -6 15 -29 17 -67 3 -91 2 -93 -73 -93 l-64 0 0 78 c0 43 3 82 7 85 10
10 96 8 113 -3z m290 -10 c0 -17 -7 -20 -45 -20 -39 0 -45 -3 -51 -25 -3 -14
-3 -36 0 -50 6 -22 12 -25 51 -25 33 0 45 -4 45 -15 0 -11 -12 -15 -49 -15
-64 0 -81 17 -81 79 0 69 20 89 93 90 31 1 37 -2 37 -19z m186 6 c15 -12 19
-27 19 -76 0 -43 -4 -63 -15 -70 -21 -14 -104 -12 -118 2 -7 7 -12 39 -12 75
0 57 2 65 23 73 34 13 81 12 103 -4z m133 -8 c5 -13 15 -38 23 -57 l14 -35 21
55 c17 45 24 54 44 54 23 0 24 -3 27 -82 2 -69 0 -83 -13 -83 -12 0 -15 13
-16 58 l-1 57 -21 -47 c-15 -36 -26 -48 -42 -48 -16 0 -27 12 -42 48 l-21 47
-1 -57 c-1 -45 -5 -58 -16 -58 -12 0 -15 15 -15 78 0 43 3 82 7 85 14 15 44 6
52 -15z m-2519 -133 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8
0 15 -7 15 -15z m2015 4 c10 -15 -1 -23 -20 -15 -9 3 -13 10 -10 16 8 13 22
13 30 -1z"/>
<path d="M1880 2184 c0 -17 89 -22 102 -7 6 7 7 13 2 14 -69 5 -104 2 -104 -7z"/>
<path d="M2078 2194 c-4 -3 -4 -12 -1 -20 4 -10 24 -14 70 -14 56 0 64 2 61
18 -3 13 -15 18 -64 20 -33 2 -63 0 -66 -4z"/>
<path d="M2030 2181 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M2225 2180 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2290 2180 c0 -10 13 -12 90 -12 77 0 90 2 90 12 0 6 -37 10 -90 10
-53 0 -90 -4 -90 -10z"/>
<path d="M2503 3217 c-18 -18 -33 -42 -33 -53 1 -17 2 -17 13 -3 7 9 27 25 45
36 27 17 30 22 19 36 -11 14 -15 13 -44 -16z"/>
<path d="M2592 3817 c-42 -44 -20 -86 23 -43 27 27 34 66 12 66 -8 0 -23 -10
-35 -23z"/>
<path d="M2720 3824 c0 -10 7 -14 22 -12 32 5 35 28 4 28 -17 0 -26 -5 -26
-16z"/>
<path d="M2703 3783 c-7 -2 -13 -11 -13 -19 0 -22 54 -14 58 9 3 17 -17 22
-45 10z"/>
<path d="M912 1301 c-9 -5 -12 -28 -10 -77 l3 -69 60 0 60 0 3 69 c3 79 -2 86
-63 86 -22 0 -46 -4 -53 -9z"/>
<path d="M1773 1303 c-9 -3 -13 -29 -13 -79 l0 -75 63 3 62 3 3 63 c2 38 -2
69 -9 78 -11 13 -79 18 -106 7z"/>
<path d="M2600 1284 c-12 -29 -13 -59 -4 -103 6 -30 8 -31 64 -31 67 0 70 4
70 85 0 63 -13 75 -80 75 -34 0 -42 -4 -50 -26z"/>
<path d="M4220 1230 l0 -80 60 0 c57 0 60 1 60 24 0 22 -5 25 -42 28 -35 2
-44 7 -46 25 -3 19 2 22 40 25 38 3 43 6 43 28 0 23 -4 25 -57 28 l-58 3 0
-81z"/>
<path d="M2172 653 c3 -48 3 -48 41 -51 l37 -3 0 50 0 51 -41 0 -40 0 3 -47z"/>
<path d="M3450 649 l0 -50 38 3 c45 4 52 31 10 36 -39 5 -37 32 2 32 20 0 30
5 30 15 0 11 -11 15 -40 15 l-40 0 0 -51z"/>
<path d="M3922 653 c3 -48 3 -48 41 -51 l37 -3 0 50 0 51 -41 0 -40 0 3 -47z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
